<template>
  <v-card
    flat
    class="stack-insights-card">
    <v-icon
      class="stack-insights-card__icon"
      size="192">
      fa-cubes
    </v-icon>
    <v-card-text class="stack-insights-card__content">
      <p class="font-weight-bold mb-0">
        {{ $t('title') }}
      </p>
      <p class="mb-2">
        {{ $t('subtitle') }}
      </p>
      <div class="d-flex flex-column space-y-1">
        <a
          :href="$docLinks.stacks.overview"
          rel="noopener noreferrer"
          target="_blank"
          class="cy-link cy-link--external">{{ $t('links.concept') }}</a>
        <a
          :href="$docLinks.stacks.design"
          rel="noopener noreferrer"
          target="_blank"
          class="cy-link cy-link--external">{{ $t('links.design') }}</a>
        <a
          :href="$docLinks.stacks.stepByStep"
          rel="noopener noreferrer"
          target="_blank"
          class="cy-link cy-link--external">{{ $t('links.stepByStep') }}</a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CyWizardStackInsightsCard',
  i18n: {
    messages: {
      en: {
        title: 'New to stacks?',
        subtitle: 'Check out the following guides to get up and running:',
        links: {
          concept: 'The stack concept',
          design: 'Designing a stack',
          stepByStep: 'Step-by-step stack creation guide',
        },
      },
      es: {
        title: '¿Nuevo en stacks?',
        subtitle: 'Consulta las siguientes guías para comenzar:',
        links: {
          concept: 'El concepto de stack',
          design: 'Diseñando una stack',
          stepByStep: 'Guía paso a paso para crear una stack',
        },
      },
      fr: {
        title: 'Nouveau dans les stacks ?',
        subtitle: 'Consultez les guides suivants pour commencer :',
        links: {
          concept: 'Le concept de stack',
          design: `Conception d'une stack`,
          stepByStep: 'Guide de création de stack étape par étape',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.stack-insights-card {
  position: relative;
  overflow: hidden;
  border: solid 1px cy-get-color("secondary", "light-2");
  background-color: cy-get-color("secondary", "light-4");

  &__icon {
    position: absolute;
    top: 0;
    right: -#{$spacer-4};
    color: cy-get-color("secondary", "light-3");
  }

  &__content {
    position: relative;
  }
}
</style>
